import * as ActionTypes from '../constants/messages.constants'

const initialState = {
  messages: [],
  chatId: null,
  loadings: {
    messages: false,
  },
  errors: {
    messages: null,
  },
}

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MESSAGES_INFO_REQUEST: {
      return {
        ...state,
        loadings: { ...state.loadings, messages: true },
        errors: { ...state.errors, messages: null },
      }
    }
    case ActionTypes.MESSAGES_INFO_SUCCESS: {
      const messages = action.payload.sort(
        (a, b) => new Date(b.time) - new Date(a.time),
      )
      return {
        ...state,
        loadings: { ...state.loadings, messages: false },
        errors: { ...state.errors, messages: null },
        messages,
        chatId: state.chatId
          ? {
              ...action.payload.find((c) => c.id === state.chatId.id),
            }
          : null,
      }
    }
    case ActionTypes.MESSAGES_INFO_ERROR: {
      return {
        ...state,
        loadings: { ...state.loadings, messages: false },
        errors: { ...state.errors, messages: action.payload },
      }
    }
    case ActionTypes.MESSAGES_SEND_REQUEST: {
      return {
        ...state,
      }
    }
    case ActionTypes.MESSAGES_SEND_SUCCESS: {
      const oldChatIndex = state.messages.findIndex(
        (m) => m.id === action.payload.id,
      )
      if (oldChatIndex !== -1) {
        const updatedChat = {
          ...state.messages[oldChatIndex],
          message: action.payload.message,
          image: action.payload.image,
          voice_note: action.payload.voice_note,
          createdAt: action.payload.createdAt,
          messages: [
            ...state.messages[oldChatIndex].messages,
            action.payload.messages,
          ],
        }
        const messages = [
          ...state.messages.slice(0, oldChatIndex),
          updatedChat,
          ...state.messages.slice(oldChatIndex + 1),
        ]

        return {
          ...state,
          messages: messages.sort(
            (a, b) => new Date(b.time) - new Date(a.time),
          ),
          chatId: state.chatId ? updatedChat : null,
          loadings: { messages: false },
        }
      } else {
        const messages = [
          ...state.messages,
          { ...action.payload, messages: [action.payload.messages] },
        ]
        return {
          ...state,
          messages: messages.sort(
            (a, b) => new Date(b.time) - new Date(a.time),
          ),
          chatId: state.chatId
            ? {
                ...action.payload,
                messages: [...state.chatId.messages, action.payload.messages],
              }
            : null,
          loadings: { messages: false },
        }
      }
    }
    case ActionTypes.MESSAGES_SEND_ERROR: {
      return {
        ...state,
      }
    }
    case ActionTypes.CHAT_ID: {
      return {
        ...state,
        chatId: action.payload,
      }
    }
    default:
      return state
  }
}
export default messagesReducer
