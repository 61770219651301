import * as ActionTypes from '../constants/messages.constants'

export const messagesInfo = (values) => ({
  type: ActionTypes.MESSAGES_INFO_REQUEST,
  payload: values,
})
export const messagesInfoSuccess = (values) => ({
  type: ActionTypes.MESSAGES_INFO_SUCCESS,
  payload: values,
})
export const messagesInfoError = (error) => ({
  type: ActionTypes.MESSAGES_INFO_ERROR,
  payload: error,
})

export const messagesSendInfo = (values) => ({
  type: ActionTypes.MESSAGES_SEND_REQUEST,
  payload: values,
})
export const messagesSendInfoSuccess = (values) => ({
  type: ActionTypes.MESSAGES_SEND_SUCCESS,
  payload: values,
})
export const messagesSendInfoError = (error) => ({
  type: ActionTypes.MESSAGES_SEND_ERROR,
  payload: error,
})
export const chatId = (error) => ({
  type: ActionTypes.CHAT_ID,
  payload: error,
})
