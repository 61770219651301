import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import * as actions from '../../actions'
import audiobell from '../../assets/audio/got-it-done-613.mp3'
import audiobellM from '../../assets/audio/telegram_soundin.mp3'

function SocketClient({ socket, user, children }) {
  const dispatch = useDispatch()
  const audioRef = useRef()
  const audioRef2 = useRef()

  useEffect(() => {
    if (user) {
      dispatch(actions.joinUserInfo(user))
    }
  }, [dispatch, user])

  useEffect(() => {
    dispatch(actions.messagesInfo())
  }, [dispatch])

  useEffect(() => {
    socket.on('checkUserOnlineToMe', (data) => {
      dispatch(actions.joinUserInfoSuccess(data))
    })
    return () => {
      socket.off(`checkUserOnlineToMe`)
    }
  }, [dispatch, socket])

  useEffect(() => {
    if (user) {
      socket.on(`allMessagesUsers${user.id}`, (data) => {
        dispatch(actions.messagesSendInfoSuccess(data))
      })
      return () => {
        socket.off(`allMessagesUsers${user.id}`)
      }
    }
  }, [dispatch, socket, user])

  return (
    <>
      <audio controls ref={audioRef} style={{ display: 'none' }}>
        <source src={audiobell} type="audio/mp3" />
      </audio>
      <audio controls ref={audioRef2} style={{ display: 'none' }}>
        <source src={audiobellM} type="audio/mp3" />
      </audio>
      {children}
    </>
  )
}
export default SocketClient
